@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
.toast-custom {
  width: 100% !important;
}
.toast-custom .toast-custom-container {
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 0 10px 0 5px;
  letter-spacing: 1px;
  width: -moz-fit-content !important;
  width: fit-content !important;
  margin: auto !important;
  margin-top: 10px !important;
}
.toast-custom .toast-custom-container .Toastify__toast-body {
  flex: 1 1;
}
.toast-custom .toast-custom-container .Toastify__toast-icon {
  width: 45px !important;
  height: 45px !important;
  background: #FFF !important;
  padding: 10px !important;
  border-radius: 50px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.toast-custom .toast-custom-container .Toastify__close-button {
  background: #47484B !important;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: unset;
}

.btn-paste {
  background: #F4F1F0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #595C5C !important;
}
.btn-paste img {
  margin-right: 10px !important;
}

.login-wrapper {
  min-height: 100vh;
  overflow: hidden;
  padding: 0 5%;
}
.login-wrapper .login-card {
  border: 1px solid #EFEFF0;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.0784313725);
  border: 1px solid #EFEFF0;
  border-radius: 13px;
  padding: 25px;
  max-width: 380px;
  margin: 10% auto;
}
.login-wrapper .login-card .login-form-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #EFEFEF;
  border-radius: 50%;
  width: 53px;
  height: 53px;
  margin-bottom: 15px;
}
.login-wrapper .login-card h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.5;
  color: #131517;
  margin-bottom: 5px;
}
.login-wrapper .login-card p {
  font-weight: 400;
  font-size: 14px;
  color: #595C5C;
}

.powered-by {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: auto;
  color: #8A8A8E;
  font-size: 12px;
  bottom: 30px;
  position: fixed;
  left: calc(50% - 60px);
}

.content-wrapper {
  padding: 15px;
  min-height: calc(100vh - 50px);
  background-color: #F9F9F9;
  padding-top: 74px;
}

.main-content {
  padding: 20px 0;
}

.custom-card {
  border: 1px solid #EFEFF0;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.0784313725);
  border: 1px solid #EFEFF0;
  border-radius: 13px;
  padding: 25px;
}

.status-render {
  display: flex;
  font-size: 12px;
  align-items: center;
}
.status-render i {
  font-size: 8px;
  margin-right: 5px;
}

.page-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 1.5;
}

.event-card {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.0784313725);
  border: 1px solid #EFEFF0;
  border-radius: 13px;
  padding: 15px;
  margin-bottom: 15px;
}
.event-card .left-content {
  display: flex;
  margin-right: auto;
}
.event-card .left-content .event-logo-info {
  max-width: 150px;
  background: #EDEDED;
  border-radius: 8px;
  margin-right: 10px;
  padding: 5px;
}
.event-card .left-content .event-logo-info img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}
.event-card .left-content .event-logo-info p {
  font-weight: 400;
  font-size: 12px;
  color: #131517;
  text-align: center;
  line-height: 1.5;
  padding: 10px 0;
}
.event-card .left-content .event-description h3 {
  font-size: 18px;
}
.event-card .left-content .event-description p {
  color: #969498;
  font-size: 14px;
}
.event-card .right-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.error-page-container {
  display: flex;
  flex-flow: column;
  margin: auto;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.error-page-container button {
  margin-top: 20px;
}
.error-page-container .error-page-text {
  font-size: 16px;
  font-weight: 100;
  max-width: 250px;
  text-align: center;
  color: #595C5C;
  margin-top: 20px;
}

.grid-item {
  height: 200px;
  background-size: cover;
  background-position: center center;
  width: 100%;
  border-radius: 8px;
}

.ant-table-row-selected .ant-table-selection-column, .ant-table-row-selected .ant-table-cell {
  background-color: #FFF !important;
}

.cursor-pointer {
  cursor: pointer;
}/*# sourceMappingURL=styles.css.map */