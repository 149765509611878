@import '../../assets/css/variable';
@import '../../assets/css/mixins';

.topbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $header-height;
  background-color: #F9F9F9; //$background-color;
//   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  border-bottom: 1px solid #d9d9da;
  padding: 0 10px;
  
  .container {
    @include flex-between;
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
  }

  .logo {
    @include flex-center;
    gap: 8px;
    font-size: 1rem;
    font-weight: 600;
    color: $primary-color;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .navigation {
    @include flex-center;
    gap: 24px;

    @include mobile {
      display: none;
    }

    a {
      color: $text-secondary;
      text-decoration: none;
      font-weight: 400;
      font-size: 14px;
      &:hover {
        color: $primary-color;
      }
      
      &.active {
        color: $primary-color;
        text-decoration: underline;
      }
    }
  }

  .mobileMenu {
    display: none;
    
    @include mobile {
      display: block;
    }
  }

  .profile {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;

    .time{
      font-size: 12px;
      color:$text-secondary
    }

    @include mobile{
      display: none;
    }
  }
}