@import "variable";

html,
body{
    color: #131517;
    background: #F9F9F9;
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
p{
    margin:0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-input-placeholder, :-moz-placeholder, ::-moz-placeholder, :-ms-input-placeholder, ::-ms-input-placeholder, ::placeholder {
    color: #D4D4D4 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

input.form-control, select.form-select, textarea.form-control {
    border: 1px solid #131517;
    border-radius: 8px;
}

.form-control:focus { 
    box-shadow: none !important;
    border-color: #131517 !important;
}

input.otp-input {
    width: 40px !important;
    margin-right: 5px;
    border-color: #DFE0E1;
}

label {
    font-size: 14px;
    color: #131517;
    font-weight: 600;
    margin-bottom: 10px;
}

.btn.btn-primary {
    background-color: #333537 !important;
    border-color: #333537 !important;
    font-size: 1rem !important;
    font-weight: 600;
    border-radius: 8px;
    &:hover, &:focus, &:active {
        background-color: #2c2e30 !important;
        border-color : #2c2e30 !important;
    }
}

.btn-link{
    text-decoration: none !important;
    color : #969498 !important;
}

.breadcrumb{
    background-color: transparent !important;
    padding: 0 !important;
    margin-bottom: 0 !important;
    font-size: 14px !important;
    .breadcrumb-item a{
        text-decoration: none !important;
        color:$text-secondary !important;
        i{
          margin-right: 5px;  
        }
    }
}