@mixin mobile {
    @media (max-width: #{$mobile}) {
      @content;
    }
  }
  
  @mixin tablet {
    @media (min-width: #{$mobile + 1}) and (max-width: #{$tablet}) {
      @content;
    }
  }
  
  @mixin desktop {
    @media (min-width: #{$tablet + 1}) {
      @content;
    }
  }
  
  @mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @mixin flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }