$white: #fff;
$black: #000;

$btn-primary: #131517;
$btn-primary-hover:#333537;

$text-secondary : #595C5C;

$secondry: #65615d;

$bg-color: #F9F9F9;

$montserrat: "Montserrat", sans-serif;
$openSans: "Open Sans", sans-serif;
$laptop: 1536px;
$smlaptop: 1236px;
$ipad: 991px;
$mobile: 767px;

// Colors
$primary-color: #131517;
$text-color: rgba(0, 0, 0, 0.88);
$border-color: #d9d9d9;
$background-color: #ffffff;

// // Breakpoints
// $mobile: 576px;
// $tablet: 768px;
// $desktop: 1024px;

// Layout
$header-height: 50px;
$container-padding: 0px;